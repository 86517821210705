import { LogEntry } from './types';

const clientConsoleEnabled =
  process.env['NEXT_PUBLIC_CLIENT_CONSOLE_ENABLED'] === 'true';

type ConsoleLog =
  | typeof console.warn
  | typeof console.info
  | typeof console.error
  | typeof console.debug;

export const logDevTimePinoMessage = (logEntry: LogEntry, log: ConsoleLog) => {
  if (!clientConsoleEnabled) return;

  if (logEntry?.err) {
    if (logEntry?.data) {
      log(logEntry.msg, logEntry.err, logEntry.data);
    } else {
      log(logEntry.msg, logEntry.err);
    }
  } else if (logEntry?.data) {
    log(logEntry.msg, logEntry.data);
  } else {
    log(logEntry.msg);
  }
};
