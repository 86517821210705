/* eslint-disable no-console */
import { buildDatadogMessageContext } from './datadog/buildDatadogMessageContext';
import { buildPinoMessage } from './pinoSetup/buildPinoMessage';
import { logDevTimePinoMessage } from './pinoSetup/dev';
import { send } from './pinoSetup/legacyClientApiReporting';
import { LogEntry } from './pinoSetup/types';
import { StatusType, datadogLogs } from '@datadog/browser-logs';
// eslint-disable-next-line import/no-named-as-default
import pino, { LoggerOptions } from 'pino';

const clientReportingEnabled =
  process.env['NEXT_PUBLIC_CLIENT_REPORTING_ENABLED'] === 'true';

const onServer = typeof window === 'undefined';

const datadogBrowserLogs =
  process.env['NEXT_PUBLIC_DATADOG_BROWSER_LOGS'] === 'true';

const clientDatadogBrowserLogs = !onServer && datadogBrowserLogs;

const clientLogLevel = process.env['NEXT_PUBLIC_CLIENT_LOG_LEVEL'];

const pinoPrettyEnabled = process.env['PINO_PRETTY_ENABLED'] === 'true';

/** pino log levels https://github.com/pinojs/pino/blob/master/docs/api.md#loggerlevel-string-gettersetter
 * 10 - trace
 * 20 - debug
 * 30 - info
 * 40 - warn
 * 50 - error
 * 60 - fatal
 */

const pinoConfig: LoggerOptions = {
  level: process.env['LOG_LEVEL'] ?? 'trace',
  browser: {
    asObject: true,
    write: {
      trace: (log) => logDevTimePinoMessage(log as LogEntry, console.debug),
      debug: (log) => logDevTimePinoMessage(log as LogEntry, console.debug),
      info: (log) => logDevTimePinoMessage(log as LogEntry, console.info),
      warn: (log) => logDevTimePinoMessage(log as LogEntry, console.warn),
      error: (log) => logDevTimePinoMessage(log as LogEntry, console.error),
      fatal: (log) => logDevTimePinoMessage(log as LogEntry, console.error),
    },
  },
  // Do not use pino to provide hostname and pid
  base: undefined,
  formatters: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    level(label, number) {
      return { level: label };
    },
  },
};

if (clientReportingEnabled && pinoConfig.browser) {
  pinoConfig.browser.transmit = {
    // The 'level' here simply defines the lowest level we care about in pino
    // https://github.com/pinojs/pino/blob/master/pino.d.ts#L534
    level: clientLogLevel ?? 'info',
    send,
  };
}

if (pinoPrettyEnabled) {
  pinoConfig.transport = {
    target: 'pino-pretty',
    options: {
      colorize: true,
    },
  };
}

const pinoLogger = pino(pinoConfig);

datadogLogs.createLogger('client', {
  level: (clientLogLevel as StatusType) ?? 'info',
});

export const logger = {
  trace: (message: string, data?: unknown) => {
    pinoLogger.trace(buildPinoMessage(message, data), message);
  },
  debug: (message: string, data?: unknown) => {
    pinoLogger.debug(buildPinoMessage(message, data), message);
  },
  info: (message: string, data?: unknown) => {
    if (clientDatadogBrowserLogs) {
      datadogLogs
        .getLogger('client')
        ?.info(message, buildDatadogMessageContext(data));
      return;
    }

    pinoLogger.info(buildPinoMessage(message, data), message);
  },
  warn: (message: string, data?: unknown) => {
    if (clientDatadogBrowserLogs) {
      datadogLogs
        .getLogger('client')
        ?.warn(message, buildDatadogMessageContext(data));
      return;
    }

    pinoLogger.warn(buildPinoMessage(message, data), message);
  },
  error: (message: string, error: unknown, data?: unknown) => {
    if (clientDatadogBrowserLogs) {
      datadogLogs
        .getLogger('client')
        ?.error(
          message,
          buildDatadogMessageContext(data),
          error instanceof Error ? error : undefined,
        );
      return;
    }
    pinoLogger.error(buildPinoMessage(message, data, error), message);
  },
};
