/*
example implementations you can pass in:
## next.js
import router as nextRouter from 'next/router';
export const router: Router = {
  push: nextRouter.push,
}
## tanstack-router (not confirmed)
import { router as tanstackRouter} from '../your/tanstackRouter';
export const router: Router = {
  push: (url: string) => tanstackRouter.navigate({to:url}),
}
*/

export type Router = {
  push: (path: string) => unknown;
};

/**
 * Library agnostic window router
 */
export const windowRouter: Router = {
  push: (url: string) => {
    window.location.assign(url);
  },
};
