import { Level, LogEvent } from 'pino';
import { safeJsonStringify } from 'safe-json-utils';

export const apiMap: Record<Level, string> = {
  error: '/api/error',
  warn: '/api/warn',
  info: '/api/info',
  fatal: '/api/error',
  debug: '/api/info',
  trace: '/api/info',
};

export const send = (level: Level, logEvent: LogEvent) => {
  const payload = safeJsonStringify({ messages: logEvent.messages, level });
  // eslint-disable-next-line eqeqeq
  if (typeof navigator != 'undefined') {
    navigator.sendBeacon(apiMap[level], payload);
  }
};
