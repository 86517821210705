export const POST_LOGIN_RETURN_TO_COOKIE_NAME = 'postLoginReturnTo';

export const USER_AUTHENTICATION_STATUS_REQUEST_HEADER =
  'x-req-is-user-authenticated';
export const USER_CONSUMER_ID_REQUEST_HEADER = 'x-req-consumer-id';
export const USER_INFO_REQUEST_HEADER = 'x-req-current-user';
export const USER_ACCESS_TOKEN_REQUEST_HEADER = 'x-req-user-access-token';
export const USER_SESSION_ERROR_REQUEST_HEADER = 'x-req-session-error';
export const EPHEMERAL_REDIRECT_COOKIE_NAME = 'oauth_redirect_origin';
export const THRIVENT_TLD_COOKIE_OPTIONS = {
  path: '/',
  domain: 'thrivent.com',
};

// Used for legacy apps as well as CMS to determine authenticated state
export const THRIVENT_LOGGED_IN_COOKIE_NAME = 'ThriventLoggedIn';

// Used by idle user timeout to share last active times between different thrivent.com origins
export const USER_LAST_ACTIVE_TIME_COOKIE_NAME = 'userLastActiveTime';

export const USER_ACTIVE_EVENTS: string[] = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'focus',
];
