type MessageContext = {
  data?: unknown;
  err?: unknown;
};

export const buildDatadogMessageContext = (data?: unknown): MessageContext => {
  if (data === null || data === undefined) {
    return {};
  }

  return { data };
};
