import type { AsyncStorage, StateStore } from './imports';
import { InMemoryWebStorage, OidcClientUser } from './imports';

export class UserStore implements StateStore {
  private readonly _store: AsyncStorage | Storage;
  private readonly _prefix: string;

  public constructor({
    prefix,
    store,
  }: { prefix?: string; store?: Storage } = {}) {
    this._store =
      typeof window === 'undefined'
        ? new InMemoryWebStorage()
        : window.sessionStorage;
    this._prefix = prefix || 'oidc.';
  }

  /**
   * When saving user data to storage, the refresh_token is removed.
   * We need to ensure that global session management is the only mechanism by which a new access token can be obtained.
   * @param key
   * @param value
   */
  public async set(key: string, value: string): Promise<void> {
    const user = OidcClientUser.fromStorageString(value);
    delete user.refresh_token;
    const storageValue = user.toStorageString();
    const storageKey = `${this._prefix}${key}`;
    await this._store.setItem(storageKey, storageValue);
  }

  public async get(key: string): Promise<string | null> {
    const storageKey = `${this._prefix}${key}`;
    const item = await this._store.getItem(storageKey);
    return item;
  }

  public async remove(key: string): Promise<string | null> {
    const storageKey = `${this._prefix}${key}`;
    const item = await this._store.getItem(storageKey);
    await this._store.removeItem(storageKey);
    return item;
  }

  public async getAllKeys(): Promise<string[]> {
    const len = await this._store.length;

    const keys = [];
    for (let index = 0; index < len; index++) {
      const key = await this._store.key(index);
      if (key && key.startsWith(this._prefix)) {
        keys.push(key.substring(this._prefix.length));
      }
    }
    return keys;
  }
}
