import { LogEntry } from './types';
import { err as errorSerializer } from 'pino-std-serializers';

export const buildPinoMessage = (
  message: string,
  data?: unknown,
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  error?: Error | unknown,
) => {
  if (!data && !error) return message;
  const logEntry: LogEntry = { msg: message };
  if (data) {
    logEntry.data = data;
  }
  if (error instanceof Error) {
    logEntry.err = errorSerializer(error);
  } else {
    logEntry.err = JSON.stringify(error);
  }
  return logEntry;
};
