import { useAuthConfig } from './OIDCProvider';
import {
  deletePostLoginReturnToCookie,
  deleteThriventLoggedInCookie,
  getPostLoginReturnToCookie,
  setThriventLoggedInCookie,
} from './cookieHelpers';
import type { UserManager } from './imports';
import { Router } from './router';
import { logger } from '@thrivent-web/logging-utils';
import { FC, useCallback, useEffect, useRef } from 'react';

const isInIFrame =
  typeof window !== 'undefined' &&
  window?.location !== window?.parent?.location;

const getIsOnCallbackPage = (redirectUri: string, location: Location) => {
  let isOnCallbackPage = false;
  try {
    isOnCallbackPage = new URL(redirectUri).pathname === location.pathname;
  } catch (e) {
    logger.error(
      'Authentication Library | CallbackHandler | redirect_uri check',
      e,
    );
  }
  return isOnCallbackPage;
};

export const CallbackHandler: FC<{
  userManager: UserManager;
  defaultedLoginPageRoute: string;
  router: Router;
  onSigninCallbackError?: (error: Error) => void;
}> = ({
  userManager,
  defaultedLoginPageRoute,
  router,
  onSigninCallbackError,
}) => {
  const didInitialize = useRef(false);
  const { redirect_uri } = useAuthConfig();

  /**
   * Redirection after failed or successful signin allows for deep linking as
   * well as handling of invalid bookmarks. This functionality is disabled when
   * the app is running in an iframe. The iframe is used solely for checking
   * authentication state and should not load any application specific pages.
   * If postLoginReturnTo cookie is set, the user will be redirected to that page.
   * If postLoginReturnTo cookie is not set, the user will be redirected to the app root.
   */
  const push = useCallback(() => {
    if (isInIFrame) return;
    const postLoginReturnTo = getPostLoginReturnToCookie();
    deletePostLoginReturnToCookie();
    const pushUrl =
      postLoginReturnTo && postLoginReturnTo !== defaultedLoginPageRoute
        ? postLoginReturnTo
        : '/';
    router.push(pushUrl);
  }, [defaultedLoginPageRoute, router]);

  useEffect(() => {
    // Prevent multiple runs of the callback handler to avoid attempts to process the same auth code multiple times
    if (didInitialize.current) return;

    const queryString = window.location.search;
    const urlSearchParams = new URLSearchParams(queryString);
    const code = urlSearchParams.get('code');
    const state = urlSearchParams.get('state');
    const error = urlSearchParams.get('error');

    // If redirect_uri path is loaded but no code, state or error is provided, the request to load the redirect_uri is invalid
    // redirect to the page they were on before the login attempt or the root if not set.
    // code and state will exist with successful login, error will exist when session check is made and no session exists.
    const isOnCallbackPage = getIsOnCallbackPage(redirect_uri, window.location);
    if (!code && !state && !error && isOnCallbackPage) {
      logger.info(
        'Authentication Library | CallbackHandler | no code, state or error provided to redirect_uri',
      );
      push();
      return;
    }

    // The auth library allows for individual components to override the default redirect page.
    // If the default redirect_uri is overridden, the callback handler will still need to process
    // the code and state, but the `isOnCallbackPage` check will return false.
    // This effect should not be prevented from running based on the current window.location.
    // The signinCallback handler can only run when when both code and state are provided
    if (!code || !state) return;

    // Start the signinCallback process to exchange the code for an access token
    didInitialize.current = true;

    userManager
      .signinCallback(window.location.href)
      .then((user) => {
        // userManager.signinCallback from iframe will send message to parent window to exchange code for token
        // the `user` will always be null when the call is made within an iframe used for silent signin or session check
        if (isInIFrame) return;
        if (!user) {
          deleteThriventLoggedInCookie();
          logger.error(
            'Authentication Library | CallbackHandler | user could not be retrieved from signinCallback',
            new Error(),
          );
        } else {
          setThriventLoggedInCookie();
        }
      })
      .catch((error) => {
        if (error.message === 'No matching state found in storage') {
          logger.info(
            'Authentication Library | CallbackHandler | no matching state found - possible bookmarked callback page',
          );
        } else {
          logger.error('Authentication Library | CallbackHandler', error);
        }
        if (onSigninCallbackError) {
          onSigninCallbackError(
            error instanceof Error
              ? error
              : new Error('Unknown error occurred during signin callback.'),
          );
        }
      })
      .finally(() => {
        // regardless of outcome, the user should not remain on the callback page if not loaded in an iframe.
        push();
      });
  }, [
    userManager,
    defaultedLoginPageRoute,
    push,
    onSigninCallbackError,
    redirect_uri,
  ]);
  return null;
};
