import { useAuthConfig } from './OIDCProvider';
import {
  getPostLoginReturnToCookie,
  setPostLoginReturnToCookie,
} from './cookieHelpers';
import { AuthContextProps, useAuth as originalUseAuth } from './imports';
import { ExtraSigninRequestArgs } from './types';
import { useCallback } from 'react';

/**
 * A hook that gives you auth state and status as well as functions to handle it
 * Extends `react-oidc-context/useAuth`
 * @returns `AuthContextProps`
 */
export const useAuth = (): AuthContextProps => {
  const { loginPageRoute } = useAuthConfig();
  const { signinRedirect: originalSigninRedirect, ...originalAuthProps } =
    originalUseAuth();

  const signinRedirect = useCallback(
    (args: ExtraSigninRequestArgs) => {
      const pathName = window?.location?.pathname;
      const returnToCookie = getPostLoginReturnToCookie();
      if (pathName && pathName !== loginPageRoute && !returnToCookie) {
        setPostLoginReturnToCookie(pathName);
      }
      return originalSigninRedirect(args);
    },
    [originalSigninRedirect, loginPageRoute],
  );

  return {
    ...originalAuthProps,
    signinRedirect,
  };
};
